<template>
   <v-app>
      <v-main class="ma-0" >
         <div class="login-container d-flex align-center" >
            <v-card
               class="glass mx-auto pa-5 text-center"
               width="500"
               max-width="500"
               justify="space-around"
                transition="slide-y-transition"
               timeout="3000"
            >
               <v-form ref="form" @submit.prevent="signin">
                  <img class="mblogo align-center" src="@/assets/logo_wide.png" />
                  <h4 class="text-center"><br></h4>
                  <v-spacer></v-spacer>
                  <v-text-field
                     v-model="login"
                     placeholder="Email"
                     type="email"
                     append-icon="mdi-account"
                     required
                     solo
                     light
                     autocomplete="email"
                  ></v-text-field>
                  <v-text-field
                     v-model="password"
                     append-icon="mdi-lock"
                     placeholder="Password"
                     type="password"
                     hide-details="auto"
                     solo
                     light
                     autocomplete="current-password"
                  ></v-text-field>
                  <v-card-actions class="justify-end pa-0 pt-5">
                     <router-link to="/recover" class="forgotPassowrd">
                        Forgot password?
                     </router-link>
                     <v-spacer></v-spacer>
                     <v-btn
                        class="align-center"
                        :loading="loading"
                        color="primary"
                        type="submit"
                        x-large
                     >
                        Sign-in
                     </v-btn>
                  </v-card-actions>
               </v-form>
            </v-card>
         </div>
         <div class="credit">
            <div>
               photo by <a :href="photoInfoUrl" target="_blank">{{photoInfoName}} </a> on <a href="https://unsplash.com/?utm_source=metabim&utm_medium=referral"> Unsplash</a>
            </div>
         </div>
         <v-snackbar
            v-model="snackbar"
            :color="messageType"
            elevation="10"
            transition="slide-y-transition"
            timeout="3000"
            @input="resetSnackbar"
         >
            {{ message }}
         </v-snackbar>

      </v-main>
   </v-app>
</template>
<style scoped>
.mblogo {
  padding-top:10px;
  width: 250px;
}
.login-container {
  width: 100%;
  height: 100%;
}
.v-label
{
   color: #fff !important;
}
</style>
<script>
import { createApi } from 'unsplash-js';
import nodeFetch from 'node-fetch';
import { auth } from '@/main';
import Util from '@/utils';

export default {
   data() {
      return {
         unsplash: createApi({
            accessKey: 'ugIYTA1RzIKC5AKJL0uNUd-AMAlEFikQP4if-P4I924',
            fetch: nodeFetch,
         }),
         photo: '',
         photoInfoUrl: '',
         photoInfoName: '',
         snackbar: false,
         auth: auth,
         login: '',
         password: '',
         loading: false,
         passwordType: 'password',
      };
   },
   computed: {
      message() {
         return this.$store.state.app.message;
      },
      messageType() {
         return this.$store.state.app.messageType;
      },
      isLoggedin() {
         return this.$store.getters.isLogged;
      },
   },
   watch: {
      message(val) {
         if (val) { this.snackbar = true; }
      },
      isLoggedin(val) {
         if (val) {
            this.$router.push({ path: '/home' });
         }
      },
      $route: {
         handler: function (route) {
            this.redirect = route.query && route.query.redirect;
         },
         immediate: true,
      },
   },
   mounted() {
      auth.store.dispatch('updateAuth');
      if (this.$store.state.app.message) {
         this.snackbar = true;
      }
      // get industrial image from unsplash api
      this.unsplash.photos.getRandom({
         query: 'industrial factory refinery',
         orientation: 'landscape',
         collections: ['8k1VK2dtJ1Y', '6u5oWZxOVG8'],
      }).then((result) => {
         if (result.errors) {
            console.log('error', result.errors[0]);
         } else {
            this.photo = result.response;
            this.photoInfoUrl = this.photo.user.links.html+'?utm_source=metabim&utm_medium=referral';
            this.photoInfoName = this.photo.user.name;
            document.querySelector('#app > div > main').loading = 'lazy';
            document.querySelector('#app > div > main').style.backgroundImage = `url(${this.photo.urls.regular})`;
            document.querySelector('#app > div > main').style.backgroundSize = 'cover';
            document.querySelector('#app > div > main').style.backgroundPosition = 'center';
         }
      });
   },
   methods: {
      resetSnackbar() {
         this.$store.dispatch('alertUser', { message: '', type: 'success' });
      },
      signin() {
         // this.$mixpanel.track_forms('form', 'Login');
         Util.sendEvent('Auth', 'Login');
         auth.signin(this.login, this.password);
      },
   },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }
}

a {
  color: #fff;
}
a:visited {
  color: #fff;
}
.forgotPassowrd {
  float: right;
  font-size: 85%;
}
.glass
{
   background-image: radial-gradient(circle at 50% 100%, #4accff66 0%, #00224433 50%), radial-gradient(circle at 50% 20%, rgba(0,34,68,0.9) 30%, rgba(0,34,68,0.6) 80%, rgba(0,34,68,0.4) 150%)  ;
   backdrop-filter: blur(10px) ;
   -webkit-backdrop-filter: blur(10px);
   border-radius: 15px;
   /*shadow*/
   box-shadow: 0 8px 32px 0 rgba(0,0,0, 0.8) !important;
   border: 1px solid rgba(0, 34, 68, 0.3);
}
.credit {
  position: absolute;
  background-image: linear-gradient(180deg, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.8) 100%);
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5rem 0.5rem;
  margin: 0px;
  height: 100px;
  font-size: 12px;
  color: #ffffff99;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: flex-end;
  align-items: flex-end;

  a {
    color: #fffff99 !important;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

</style>
